var scrollReveal = require('scrollreveal');

$(document).ready(function () {
    $('.anchor-scroll').click(function (e) {
        var url = $(this).attr('href');
        var id = url.substring(url.indexOf("#"));
        console.log(id);
        if ($(id).length > 0) {
            e.preventDefault();
            $('html,body').animate({ scrollTop: $(id).offset().top - 95 }, 'slow');
            $('#navbarSupportedContent').removeClass('show');
        }
    });

    tweakCarousel();

    var $album = $('.album-sm');
    var $albumContent = $album.find('.album-sm-content');
    var $showGalleryButton = $('.show-sm-gallery');
    var originalAlbunHeight = $('.album-sm').height();

    if ($albumContent.height() > $album.height()) {
        $showGalleryButton.removeClass('d-none');
    }

    $showGalleryButton.click(function (e) {
        e.preventDefault();
        var $album = $(this).siblings('.album-sm');
        var $albumContent = $album.find('.album-sm-content');
        if ($(this).hasClass('show')) {
            $album.animate({ "maxHeight": originalAlbunHeight }, {"duration": 2000});
            $(this).removeClass('show');
            $(this).find('.fa').removeClass('fa-angle-up').addClass('fa-angle-down');
        } else {
            $album.animate({ "maxHeight": $albumContent.height() }, {"duration": 2000});
            $(this).addClass('show');
            $(this).find('.fa').removeClass('fa-angle-down').addClass('fa-angle-up');
        }

    });
});

$(window).scroll(function () {
    if ($(window).scrollTop() > 100) {
        $('#backToTop').addClass('active');
    } else {
        $('#backToTop').removeClass('active');
    }

    if ($(window).scrollTop() + $(window).height() >= $(document).height()) {
        $('#backToTop').css('bottom', ($('footer').outerHeight() + 20) + 'px');
    } else {
        $('#backToTop').css('bottom', '1rem');
    }
});

// Grab all elements to scroll reveal
var $scrollElement = $('.scroll-reveal');

// Initiate scroll reveal
window.sr = scrollReveal();

// For each scroll reveal element, set the data attributes as the options
$.each($scrollElement, function () {
    var opt = $.extend({ mobile: false }, $(this).data());
    sr.reveal(this, opt);
});

/*
 * Replace all SVG images with inline SVG
 */
$('img.svg').each(function () {
    var $img = jQuery(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');

    jQuery.get(imgURL, function (data) {
        // Get the SVG tag, ignore the rest
        var $svg = jQuery(data).find('svg');

        // Add replaced image's ID to the new SVG
        if (typeof imgID !== 'undefined') {
            $svg = $svg.attr('id', imgID);
        }
        // Add replaced image's classes to the new SVG
        if (typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass + ' replaced-svg');
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr('xmlns:a');

        // Replace image with new SVG
        $img.replaceWith($svg);

    }, 'xml');

});

function tweakCarousel() {
    $('.carousel-inner').each(function () {
        if ($(this).children('.carousel-item ').length === 1) $(this).siblings('.carousel-control-prev, .carousel-control-next, .carousel-indicators').hide();

        var highestItemHeight = 0;
        $(this).children('.carousel-item ').each(function () {
            if ($(this).height() > highestItemHeight) {
                highestItemHeight = $(this).height();
            }
        });

        $(this).height(highestItemHeight);
    });
}

$(window).resize(function () {
    tweakCarousel();
});

$('#imageZoomModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget);
    var image = button.data('image');
    var title = button.data('title');
    var modal = $(this)

    modal.find('.modal-title').text(title)
    modal
        .find('.modal-body img')
        .attr('src', image)
        .attr('alt', title)
        ;
});


$('#formModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var id= button.data('id') // Extract info from data-* attributes
    // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
    // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
    var modal = $(this)
    modal.find('.modal-id').val(id)
  })

  //navbar
  $(document).on("scroll", function(){
    if
  ($(document).scrollTop() > 120){
        $("#banner").removeClass("navbar-dark");
        $("#banner").addClass("shrink navbar-light");
    
        $("#logo").attr('src',"/images/logo.png");
    }
    else
    {
        $("#banner").removeClass("shrink navbar-light");
        $("#banner").addClass("navbar-dark");
        $("#logo").attr('src',"/images/public/logo blanco.png");
    }
});


$('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
  if (!$(this).next().hasClass('show')) {
    $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
  }
  var $subMenu = $(this).next(".dropdown-menu");
  $subMenu.toggleClass('show');


  $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
    $('.dropdown-submenu .show').removeClass("show");
  });


  return false;
});